import { ThemeUIStyleObject } from 'theme-ui'
import type { Theme } from 'theme-ui'
import tailwind from '@theme-ui/preset-tailwind'
import tailwindTheme from '../../tailwind.config'

const colorPallette = tailwindTheme.theme.colors

declare module "theme-ui" {
  interface Theme {
    footer?: ThemeUIStyleObject
    texts?: ThemeUIStyleObject
  }
}

const theme: Theme = {
  ...tailwind,
  
  config: {
    useColorSchemeMediaQuery: 'system',
  },
  colors: {
    text: colorPallette.black,
    background: colorPallette.white,
    primary: colorPallette.orange,
    secondary: colorPallette.neutral,
    accent: colorPallette.teal,
    muted: colorPallette.gray,
  },
  links: {
    sideNavMenu: {
      display: 'flex',
      flexDirection: 'column',

      fontSize: '1.5rem',
      color: colorPallette.black,
      textDecoration: 'none',
      mt: '1rem',
      transition: 'opacity 300ms transform 500ms',
      /* leaving this in for later to experiment with better menu pop in
      position: 'absolute',
      top: 0,
      left: 0,
     */
      ":hover": {
        color: colorPallette.blue,
      }
    },
    },
  text: {
    mutedText: {
      color: 'muted',
      fontSize: '.8rem',
    },
  },
  badges: {
    primary: {
      fontSize: '.8rem',
      marginRight: '8px',
    },
    filter: {
      display: 'inline-block',
      padding: '8px 16px',
      border: '1px solid',
      borderColor: 'secondary',
      borderRadius: '16px',
      color: 'text',
      bg: 'transparent',
      fontSize: '.8rem',
      fontWeight: '500',
      textAlign: 'center',
      cursor: 'pointer',
      margin: '4px 8px 4px 0px',
      transition: 'background-color 300ms ease',
      ":hover": {
        bg: 'primary',
      },
    },
  },
  /* primarily styles used for MDX + master css speck */
  styles: {
    ...tailwind.styles,
    root: {
      margin: 0,
      padding: '1%',
   //   boxSizing: `border-box`,
      textRendering: `optimizeLegibility`,
      fontFamily: 'sans-serif',
    
      main: {
        alignItems: `center`,
        justifyContent: `center`,
        margin: '1%',
        borderRadius: `5px`,
      }, 
      header: {
        margin: '1%',
        padding: '8px',
        fontFamily: 'sans-serif',
        fontWeight: `bold`,
        color: 'primary',
        backgroundColor: 'background',
      },
      blockquote: {
        borderLeft: '6px solid #D1D1D0', 
        fontFamily: 'Monaco, monospace',
        fontSize: '.9rem',
        background: 'none',
        pl: '1em',
        letterSpacing: '-0.05em', 
        lineHeight: '100%',
        wordBreak: 'break-all',
      },
    },
  }
}
export default theme